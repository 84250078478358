

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCyDLFWw_-AvxLmiWKb2zRtBcU0DE6gFDE",
  authDomain: "windpeak-webpage.firebaseapp.com",
  projectId: "windpeak-webpage",
  storageBucket: "windpeak-webpage.appspot.com",
  messagingSenderId: "464394339426",
  appId: "1:464394339426:web:2d3f71c25aeb1c64137d3b",
  measurementId: "G-T6WXXFJPFT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };