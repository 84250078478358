import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {Spinner} from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import * as formik from 'formik';
import * as yup from 'yup';

import Image from "react-bootstrap/Image";
import iconArrow from "@/assets/ic_arrow@2x.png";
import {USCitiesOptions} from "@/USCitiesOptions";
import {FormattedMessage, useIntl} from 'react-intl';

// Import the necessary Firebase functions
import { logEvent } from 'firebase/analytics';
import { analytics } from "@/components/firebaseConfig";


import '../index.css';

import { BASE_API_URL } from "@/network.config";

const stateDict = {}
USCitiesOptions.map(item => {
    const cityList = []
    item.children.map(x => {
        // county
        return x.children.map(y => {
            cityList.push({
                state: item.value, county: x.value, city: y.value
            })
            return
        })
    })
    stateDict[item.value] = cityList
    return
})

const formLayout = {
    headerLabelCol: {
        sm: 4,
        textalign: "center"
    },
    labelCol: {
        sm: 4
    },
    wrapperCol: {
        sm: 8
    },
    inchWrapperCol: {
        sm: 2
    },
    sendCodeWrapperCol: {
        sm: 3
    }
}

function useFormatMessage(messageId, defaultMessage) {
    return useIntl().formatMessage({id: messageId, defaultMessage: defaultMessage})
}

export default function () {
    const {Formik} = formik;
    const useCountDown = s => {
        const [seconds, setSeconds] = useState(s);
        useEffect(() => {
            setTimeout(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
            }, 1000);
        }, [seconds]);

        return [seconds, setSeconds];
    };
    const schema = yup.object().shape({
        pickup_state: yup.string().required(),
        pickup_city: yup.string().required(),
        delivery_state: yup.string().required(),
        delivery_city: yup.string().required(),
        length: yup.number().required(),
        width: yup.number().required(),
        height: yup.number().required(),
        phone: yup.number().required().positive().integer(),
        code: yup.string().required(),
    });
    const [pickupState, setPickupState] = useState();
    const [deliveryState, setDeliveryState] = useState();
    const [phone, setPhone] = useState();
    const [seconds, setSeconds] = useCountDown(0);
    const [showAlert, setShowAlert] = useState(false);
    const [submitProcessing, setSubmitProcessing] = useState(false);
    // const []
    const pickupStateChangeEvent = (obj) => {
        const selectedValue = obj.target.value
        setPickupState(selectedValue)
    }
    const deliveryStateChangeEvent = (obj) => {
        const selectedValue = obj.target.value
        setDeliveryState(selectedValue)
    }
    const submitHandler = async (values, actions) => {
        setSubmitProcessing(true)
        try {
            const {country_code, tel} = phoneValidHandler(phone)
            const relPhone = country_code + tel
            const rawResponse = await fetch(
                `${BASE_API_URL}/api/v1/app/web/post/route`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ...values,
                        phone: relPhone,
                    })
                })
            const result = await rawResponse.json();
            if (result.code !== 0) {
                console.log(`request post route api failed:${result}`)
                return
            }
            // Log a custom analytics event for the form submission
            logEvent(analytics, 'form_submission', {
                pickup_state: values.pickup_state,
                pickup_city: values.pickup_city,
                delivery_state: values.delivery_state,
                delivery_city: values.delivery_city,
                // Add more properties as needed
            });

            setShowAlert(true)
            actions.resetForm()
        } catch (error) {
            console.log(`request post route api error:${error}`)
        } finally {
            setSubmitProcessing(false)
        }
    }
    const phoneValidHandler = (val) => {
        const rePhone = /^[+]*[(]?[0-9]{1,3}[)]?[-\s\./0-9]*$/
        if (!rePhone.test(val)) {
            return
        }
        const realPhone = val.replace(/[^\d]/ig, "")
        const tel = realPhone.substring(1)
        const country_code = realPhone.substring(0, 1)
        return {
            country_code, tel
        }
    }
    const sendCodeHandler = async () => {
        try {
            const {country_code, tel} = phoneValidHandler(phone)
            const rawResponse = await fetch(
                `${BASE_API_URL}/api/v1/app/sendsms`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        country_code,
                        tel
                    })
                })
            const result = await rawResponse.json();
            if (result.code !== 0) {
                console.log(`request send sms api failed:${result}`)
            }
            setSeconds(60);
        } catch (error) {
            console.log(`request send sms api error:${error}`)
        }
    }
    return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={submitHandler}
                initialValues={{}}
                key={"x-formik"}
            >
                {({handleSubmit, handleChange, values, touched, errors}) => (
                    <Form noValidate onSubmit={handleSubmit} className="post-router-form">
                        <div className="box">
                            <Form.Group
                                as={Row}
                                className="box-header"
                            >
                                <Form.Label column {...formLayout.headerLabelCol}>
                                    <Image src={iconArrow} className={"label-arrow-icon"}/>
                                    <span className={"h3"}>
                                        <FormattedMessage id={"page.post-route.pickup-location"}
                                                          defaultMessage={"取货点信息"}/>
                                    </span>
                                </Form.Label>
                                <Col {...formLayout.wrapperCol}>
                                    <hr/>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3 form-group-box"
                                controlId="validationFormikPickupLocation"
                            >
                                <Form.Label column {...formLayout.labelCol} aria-required>
                                    State
                                </Form.Label>
                                <Col sm={10} {...formLayout.wrapperCol}>
                                    <Form.Control
                                        required
                                        isInvalid={!touched.pickup_state && !!errors.pickup_state}
                                        as="select"
                                        type="select"
                                        value={values.pickup_state}
                                        name="pickup_state"
                                        onChange={(e) => {
                                            pickupStateChangeEvent(e)
                                            handleChange(e)
                                        }}
                                    >
                                        <option value=""></option>
                                        {USCitiesOptions.map(item => (
                                            <option value={item.value}>{item.label}</option>))}
                                    </Form.Control>
                                    <Form.Control.Feedback
                                        type="invalid">
                                        <FormattedMessage id={"page.post-route.pickup-placeholder"}
                                                          defaultMessage={"请选择取货地点"}/>
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 form-group-box" controlId="validationFormik102">
                                <Form.Label column {...formLayout.labelCol} aria-required>
                                    City
                                </Form.Label>
                                <Col {...formLayout.wrapperCol}>
                                    <Form.Control
                                        required as="select"
                                        type="select"
                                        value={values.pickup_city}
                                        onChange={handleChange}
                                        isInvalid={!!errors.pickup_city}
                                        name="pickup_city">
                                        <option value=""></option>
                                        {pickupState && stateDict[pickupState].map(item => (
                                            <option value={`${item.state}#${item.county}#${item.city}`}
                                                    key={item.city}>{item.city}</option>))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        <FormattedMessage id={"page.post-route.pickup-placeholder"}
                                                          defaultMessage={"请选择取货地点"}/>
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="box">
                            <Form.Group
                                as={Row}
                                className="box-header"
                            >
                                <Form.Label column {...formLayout.headerLabelCol}>
                                    <Image src={iconArrow} className={"label-arrow-icon"}/>
                                    <span className={"h3"}>
                                        <FormattedMessage id={"page.post-route.delivery-location"}
                                                          defaultMessage={"送货点信息"}/>
                                    </span>
                                </Form.Label>
                                <Col {...formLayout.wrapperCol}>
                                    <hr/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 form-group-box"
                                        controlId="validationFormikDeliveryState">
                                <Form.Label column {...formLayout.labelCol} aria-required>
                                    State
                                </Form.Label>
                                <Col {...formLayout.wrapperCol}>
                                    <Form.Control
                                        required
                                        as="select"
                                        type="select"
                                        name="delivery_state"
                                        isInvalid={!!errors.delivery_state}
                                        value={values.delivery_state}
                                        onChange={(e) => {
                                            deliveryStateChangeEvent(e)
                                            handleChange(e)
                                        }}
                                    >
                                        <option value=""></option>
                                        {USCitiesOptions.map(item => (
                                            <option value={item.value}>{item.label}</option>))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        <FormattedMessage id={"page.post-route.delivery-placeholder"}
                                                          defaultMessage={"请选择送货点"}/>
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 form-group-box">
                                <Form.Label column {...formLayout.labelCol} aria-required>
                                    City
                                </Form.Label>
                                <Col {...formLayout.wrapperCol}>
                                    <Form.Control
                                        required
                                        as="select"
                                        type="select"
                                        name={"delivery_city"}
                                        isInvalid={!!errors.delivery_city}
                                        value={values.delivery_city}
                                        onChange={handleChange}
                                    >
                                        <option value=""></option>
                                        {deliveryState && stateDict[deliveryState].map(item => (
                                            <option value={`${item.state}#${item.county}#${item.city}`}
                                                    key={item.city}>{item.city}</option>))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        <FormattedMessage id={"page.post-route.delivery-placeholder"}
                                                          defaultMessage={"请选择送货点"}/>
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="box">
                            <Form.Group
                                as={Row}
                                className="box-header"
                            >
                                <Form.Label column {...formLayout.headerLabelCol}>
                                    <Image src={iconArrow} className={"label-arrow-icon"}/>
                                    <span className={"h3"}>
                                        <FormattedMessage id={"page.post-route.truck-space-with-remarks"}
                                                          defaultMessage={"货车仓位及备注"}/>
                                    </span>
                                </Form.Label>
                                <Col {...formLayout.wrapperCol}>
                                    <hr/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 form-group-box" controlId="validationFormik101">
                                <Form.Label column {...formLayout.labelCol} aria-required>
                                    <FormattedMessage id={"page.post-route.truck-space-size"}
                                                      defaultMessage={"空余尺寸Inch"}/>
                                </Form.Label>
                                <Col {...formLayout.inchWrapperCol}>
                                    <Form.Group controlId="validationFormikLength">
                                        <Form.Control
                                            type="number"
                                            placeholder="Length"
                                            min={1}
                                            max={1000}
                                            name="length"
                                            value={values.length}
                                            onChange={handleChange}
                                            isInvalid={!!errors.length}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FormattedMessage id={"page.post-route.length-placeholder"}
                                                              defaultMessage={"请输入可用长度"}/>
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col {...formLayout.inchWrapperCol}>
                                    <Form.Group controlId="validationFormikWidth" className="div-form-group">
                                        <Form.Control
                                            required
                                            isInvalid={!!errors.width}
                                            name={"width"}
                                            min={1}
                                            max={1000}
                                            value={values.width}
                                            onChange={handleChange}
                                            type="number"
                                            placeholder="Width"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FormattedMessage id={"page.post-route.width-placeholder"}/>
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col {...formLayout.inchWrapperCol}>
                                    <Form.Group controlId="validationFormikHeight" className="div-form-group">
                                        <Form.Control
                                            required
                                            isInvalid={!!errors.height}
                                            name={"height"}
                                            min={1}
                                            max={1000}
                                            value={values.height}
                                            onChange={handleChange}
                                            type="number"
                                            placeholder="Height"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FormattedMessage id={"page.post-route.height-placeholder"}/>
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 form-group-box" controlId="validationFormikRemarks">
                                <Form.Label column {...formLayout.labelCol}>
                                    <FormattedMessage id={"page.post-route.note"} defaultMessage={"备注"}/>
                                </Form.Label>
                                <Col {...formLayout.wrapperCol}>
                                    <Form.Control
                                        name={"remarks"}
                                        type="text"
                                        placeholder={useFormatMessage("page.post-route.note-placeholder", "备注")}
                                    />
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="box">
                            <Form.Group
                                as={Row}
                                className="box-header"
                            >
                                <Form.Label column {...formLayout.headerLabelCol}>
                                    <Image src={iconArrow} className={"label-arrow-icon"}/>
                                    <span className={"h3"}>Contact Info</span>
                                </Form.Label>
                                <Col {...formLayout.wrapperCol}>
                                    <hr/>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3 form-group-box position-relative"
                                controlId="validationFormikPhone"
                            >
                                <Form.Label column {...formLayout.labelCol} aria-required>
                                    <FormattedMessage id={"page.post-route.phone"} defaultMessage={"电话号码"}/>
                                </Form.Label>
                                <Col sm={5}>
                                    <Form.Control
                                        required
                                        name={"phone"}
                                        type="tel"
                                        value={values.phone}
                                        onChange={(e) => {
                                            handleChange(e)
                                            setPhone(e.target.value)
                                        }}
                                        isInvalid={!!errors.phone}
                                        placeholder={useFormatMessage("page.post-route.phone-placeholder", "请输入手机号")}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {useFormatMessage("page.post-route.phone-placeholder", "请输入手机号")}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col {...formLayout.sendCodeWrapperCol} className={"send-code-col"}>
                                    <Button
                                        variant="dark"
                                        className={"btn-send-code"}
                                        onClick={sendCodeHandler}
                                        disabled={seconds !== 0}
                                    >
                                        {
                                            seconds > 0 ? `${seconds}s` : <FormattedMessage
                                                id={"page.post-route.btn-send-code"}
                                                defaultMessage={"发送验证码"}/>
                                        }
                                    </Button>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3 form-group-box">
                                <Form.Label column {...formLayout.labelCol} aria-required>
                                    <FormattedMessage id={"page.post-route.code"} defaultMessage={"验证码"}/>
                                </Form.Label>
                                <Col {...formLayout.wrapperCol}>
                                    <Form.Control
                                        required
                                        name={"code"}
                                        type="text"
                                        // placeholder="please input code"
                                        placeholder={useFormatMessage("page.post-route.code-placeholder", "please input code")}
                                        value={values.code}
                                        onChange={handleChange}
                                        isInvalid={!!errors.code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {useFormatMessage("page.post-route.code-placeholder", "please input code")}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </div>
                        <Form.Group as={Row} className="mb-3 form-group-box">
                            <Col sm={{span: 8, offset: 4}}>
                                <Alert variant="success" show={showAlert} onClose={() => setShowAlert(false)}
                                       dismissible>
                                    <Alert.Heading>
                                        <FormattedMessage id={"page.post-route.success-title"}
                                                          defaultMessage={"发布成功"}/>
                                    </Alert.Heading>
                                </Alert>
                                {
                                    submitProcessing > 0 ? <Button disabled variant="secondary" size="lg" type="submit"
                                                                   className="btn-submit">
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Processing...
                                        </Button> :
                                        <Button variant="secondary" size="lg" type="submit" className="btn-submit">
                                            <FormattedMessage id={"page.post-route.btn-submit"}
                                                              defaultMessage={"发布线路"}/>
                                        </Button>
                                }
                            </Col>
                        </Form.Group>
                    </Form>
                )}
            </Formik>

        </>
    );
}
