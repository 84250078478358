import React from 'react';
import Container from "react-bootstrap/Container";

export default function ContactUs() {
    return (
        <div className="contact-us-wrapper content-wrapper">
            <Container>
                <div className="real-content" itemProp="text">
                    <p>If you have any questions about our services, please email help@windpeak.net.</p>
                    <div className="clear"></div>
                </div>
            </Container>
        </div>
    );
}
