import React from "react";
import {useRouteError} from "react-router-dom";
import Container from "react-bootstrap/Container";

export default function ErrorPage() {
    const error = useRouteError();

    return (
        <div className="App" id="error-page">
            <div className="contact-us-wrapper content-wrapper">
                <Container>
                    <div className="real-content" itemProp="text">
                        <h1>Oops!</h1>
                        <p>Sorry, an unexpected error has occurred.</p>
                        <p>
                            <i>{error.statusText || error.message}</i>
                        </p>
                    </div>
                </Container>
            </div>
        </div>
    );
}