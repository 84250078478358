export default {
  "page.order.tab.intransit": "In transit",
  "page.order.tab.done": "Done",
  "page.order.empty.description": "No order yet",
  "page.order.item.status.2": "Awaiting pickup",
  "page.order.item.status.3": "In transit",
  "page.order.item.status.4": "Done",
  "page.order.item.status.5": "Done",
  "page.order.item.title.order.number": "Order",
  "page.order.item.title.pickup.date": "Pick up",
  "page.order.item.title.freight.category": "Freight category",
  "page.order.item.title.freight.size": "Freight size",
  "page.order.item.description.freight.size.length": "L",
  "page.order.item.description.freight.size.width": "W",
  "page.order.item.description.freight.size.height": "H",
  "page.order.item.title.estimated.range": "Four-hours window",
  "page.order.item.title.estimated.pickup": "Estimated pickup time",
  "page.order.item.title.estimated.pickup.date": "Pickup date",
  "page.order.item.title.estimated.pickup.time": "Pickup time",
  "page.order.item.title.estimated.delivery": "Estimated arrival",
  "page.order.item.title.estimated.delivery.date": "Delivery date",
  "page.order.item.title.estimated.delivery.time": "Delivery time",
  "page.order.item.title.completion.time": "Completion time",
  "page.order.item.placeholder.date": "please select",
  "page.order.item.placeholder.time": "please select",
  "page.order.item.button.view.bol": "BOL form",
  "page.order.item.button.upload.bol": "Upload BOL",
  "page.order.item.button.view.pod": "POD form",
  "page.order.item.button.upload.pod": "Upload POD",
};
