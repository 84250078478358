import React from 'react';
import Container from "react-bootstrap/Container";

export default function Announcement() {
    return (
        <div className="contact-us-wrapper content-wrapper">
            <Container>
                <div className="real-content" itemProp="text">
                    <p>Windpeak Corporation很高兴地宣布，我们已与北京优趣天下信息技术有限公司合作，共同开发先进的软件解决方案。</p>
                    <div className="clear"></div>
                </div>
            </Container>
        </div>
    );
}
