export default {
    "page.post-route.page-header": "发布线路",
    "page.post-route.pickup-location": "取货点信息",
    "page.post-route.delivery-location": "送货点信息",
    "page.post-route.truck-space-with-remarks": "货车仓位及备注",
    "page.post-route.truck-space-size": "空余尺寸Inch",
    "page.post-route.btn-send-code": "发送验证码",
    "page.post-route.code": "验证码",
    "page.post-route.phone": "电话号码",
    "page.post-route.note": "备注",
    "page.post-route.btn-submit": "发布线路",
    "page.post-route.phone-placeholder": "请输入电话号码",
    "page.post-route.code-placeholder": "请输入验证码",
    "page.post-route.note-placeholder": "请输入备注",
    "page.post-route.pickup-placeholder": "请选择取货地点",
    "page.post-route.delivery-placeholder": "请选择送货地点",
    "page.post-route.length-placeholder": "请输入可用长度",
    "page.post-route.width-placeholder": "请输入可用宽度",
    "page.post-route.height-placeholder": "请输入可用高度",
    "page.post-route.success-title": "线路发布成功，请注意查收通知！"
}