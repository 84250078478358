export default {
  "page.order.tab.intransit": "运输中订单",
  "page.order.tab.done": "已完成订单",
  "page.order.empty.description": "暂无订单",
  "page.order.item.status.2": "等待取货",
  "page.order.item.status.3": "运输中",
  "page.order.item.status.4": "已完成",
  "page.order.item.status.5": "已完成",
  "page.order.item.title.order.number": "订单号",
  "page.order.item.title.pickup.date": "取货时间",
  "page.order.item.title.freight.category": "货物类别",
  "page.order.item.title.freight.size": "货物尺寸",
  "page.order.item.description.freight.size.length": "长",
  "page.order.item.description.freight.size.width": "宽",
  "page.order.item.description.freight.size.height": "高",
  "page.order.item.title.estimated.range": "四小时区间",
  "page.order.item.title.estimated.pickup": "预计取货",
  "page.order.item.title.estimated.pickup.date": "取货日期",
  "page.order.item.title.estimated.pickup.time": "取货时间",
  "page.order.item.title.estimated.delivery": "预计送达",
  "page.order.item.title.estimated.delivery.date": "送达日期",
  "page.order.item.title.estimated.delivery.time": "送达时间",
  "page.order.item.title.completion.time": "完成时间",
  "page.order.item.placeholder.date": "请选择",
  "page.order.item.placeholder.time": "请选择",
  "page.order.item.button.view.bol": "查看BOL",
  "page.order.item.button.upload.bol": "上传BOL",
  "page.order.item.button.view.pod": "查看POD",
  "page.order.item.button.upload.pod": "上传POD",
};
