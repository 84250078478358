import React from 'react';
import Container from "react-bootstrap/Container";
import './index.css';

export default function Privacy() {
    return (
        <div className="privacy-wrapper content-wrapper">
            <Container>
                <div className="real-content" itemProp="text">
                    <p><h1>Overview</h1></p>
                    <p>Extraspace is a mobile application developed by Windpeak Corporation. Your privacy is very
                        important to all of us at Windpeak. We have established this privacy policy ("Privacy
                        Policy")&nbsp; to explain to you how your personal information is protected, collected and
                        used. It also describes your choices regarding use, access and correction of your personal
                        information. Personal information is information about you that is personally identifiable
                        such as your name, address, phone number, and email address that is not otherwise publicly
                        available.&nbsp;</p>
                    <p>The privacy practices of this statement apply to our services owned and operated by Windpeak
                        ("Windpeak", "we", "our", "us").</p>
                    <p>Windpeak is the controller of your Personal Data and responsible for the collection,
                        processing and disclosure of your Personal Data as described in this Privacy Policy unless
                        expressly specified otherwise.</p>
                    <p>By using our services, you agree to be bound by the terms and conditions of this Privacy
                        Policy and expressly consent to our use and disclosure of your personal information in
                        accordance with this Privacy Policy. If you do not agree, please do not use our
                        services.</p>
                    <p>This Privacy Policy is incorporated into and subject to the terms of the Windpeak User
                        Agreement.</p>
                    <p>We reserve the right to modify this Privacy Policy at any time, so please review it
                        frequently.</p>
                    <p><strong>Personal Data we collect</strong></p>


                    <p>In order to use our services, you must first complete the registration form and create a
                        username and password. During the registration process, you are required to give contact
                        information such as name, address, phone number, and email address. Note, once you agree to
                        provide us with personal information, you are no longer anonymous to us.</p>


                    <p>If you choose to use our services, we may require you to provide contact and identity
                        information, billing information, and other personal information as indicated on the forms
                        throughout the app. Under some circumstances we may require some additional financial
                        information. Where possible, we indicate which fields are required and which are optional.
                        You always have the option to not provide information by choosing not to use a particular
                        service or feature.</p>


                    <p>If you choose to list, quote, or match on the app, we collect information about your activity
                        and behavior. We also collect other users’ comments about you in our feedback area.</p>


                    <p>Additionally, as part of the registration process, Windpeak allows members registering as a
                        service provider the option to submit certain commercial information such as a commercial
                        service provider’s license number, DOT number, Motor Carrier (MC) number, and/or any other
                        authority a service provider chooses to include. It is up to each member individually to
                        provide this information. Windpeak will not validate this information but will allow
                        Windpeak’s 3rd party partners to access this information in order to obtain relevant
                        information such as authority, insurance coverages and other business information as
                        provided in FMCSA records and other information sources.</p>


                    <p>If you send us personal correspondence, such as emails or letters, or if other users or third
                        parties send us correspondence about your activities or postings on the app, we may collect
                        such information into a file specific to you.</p>


                    <p>From time-to-time we may provide you the opportunity to participate in surveys on the app. If
                        you choose to participate, we will request certain personal information, including your
                        email address, from you. Participation in these surveys is completely voluntary and you
                        therefore have a choice whether or not to disclose this information. The requested
                        information typically includes contact information and demographic information. We will not
                        share the personal information you provide through a survey with other third parties unless
                        we give you prior notice and choice.</p>


                    <p>If you choose to opt-in, Windpeak may contact you and deliver via text (SMS) messages or push
                        notifications, transactional information, administrative notices, operational, promotional,
                        or marketing notifications and offers, and other communications relevant to your use of the
                        Windpeak platform as necessary for our legitimate interests in proper communication and
                        engagement with our Users, and in promoting the Windpeak platform. Your agreement to receive
                        recurring operational, marketing, or promotional text messages is not a condition of any
                        purchase or use of the platform.</p>


                    <p>Receiving operational, promotional, or marketing text (SMS) messages from Windpeak is
                        completely voluntary and you may opt out at any time. To opt out of receiving text messages
                        from Windpeak, you can reply STOP to any text message you receive or contact Windpeak Member
                        Support. You may continue to receive text messages for a short period while we process your
                        request, and you may also receive text messages confirming the receipt of your opt-out
                        request. <strong>&nbsp;</strong>Message and data rates may apply.</p>


                    <p><strong>Log Files</strong></p>


                    <p>As is true of most apps, we gather certain information automatically and store it in log
                        files. This information includes internet protocol (IP) addresses, phone type, internet
                        service provider (ISP), referring/exit pages, operating system, date/time stamp, and
                        clickstream data. We use this information to analyze trends, to administer the app, to track
                        users’ movements around the app and to gather demographic information about our user base as
                        a whole.</p>


                    <p>We track trends in users’ usage and volume statistics to create a more efficient and usable
                        app, and to determine areas of the app that could be improved to enhance the user
                        experience.</p>


                    <p>Log files are used on the app, and in any link to the app from an email.</p>


                    <p><strong>How we use your personal data</strong></p>


                    <p>We collect and use your data principally for the following purposes:</p>


                    <ul>
                        <li>Provide services and features that are most likely to meet your needs and facilitate the
                            services you request
                        </li>


                        <li>Customize your experience and the content, products, and services offered to you</li>


                        <li>Verify the accuracy of your name, address, and other information</li>


                        <li>Bill you for your use of our services and collect fees owed</li>


                        <li>Provide support for your transactions, products, and services you use</li>


                        <li>Assist you to resolve disputes</li>


                        <li>Comply with legal requirements</li>


                        <li>Inform you about online and offline offers, products, services, and updates</li>


                        <li>Provide targeted advertising, including the use of re-targeting technology</li>


                        <li>Measure consumer interest in our products and services</li>


                        <li>Detect and protect us against error, fraud, and other criminal activity and enforce our
                            User Agreement
                        </li>


                        <li>Look across multiple users to identify problems or resolve disputes and identify users
                            using multiple usernames or aliases
                        </li>


                        <li>Compare and review your personal information for errors, omissions, and accuracy</li>


                        <li>Conduct internal research on our users’ demographics, interest, and behavior to better
                            understand, protect, and serve you and our community
                        </li>


                        <li>Improve our marketing and promotional efforts, analyze app usage, improve our content
                            and product offerings, and customize the app’s content, layout, and services
                        </li>


                        <li>Collect personal information on behalf of our payment processors in order to meet
                            compliance obligations, such as Know Your Customer.
                        </li>
                    </ul>


                    <p><strong>Information Sharing and Disclosure&nbsp;</strong></p>


                    <p>As a matter of policy, we do not sell, rent or share any of your personal information,
                        including your email address, to third parties for their marketing purposes without your
                        explicit consent. The profile you create on the app will be publicly accessible unless
                        otherwise indicated. You may change the privacy settings of your profile through your
                        account portal. However, the following describes some of the ways that your personal
                        information may be disclosed in the normal scope of business to provide our services.</p>


                    <p><strong>Windpeak Community:</strong> Your Windpeak username is displayed throughout the app
                        and is therefore available to the public. All of your activities on the app will be
                        identifiable to your username. Other people can see shipments you have listed, your feedback
                        rating and associated comments, your postings on the app, and so on. Therefore, if you
                        associate your name with your username, the people you have revealed your name to will be
                        able to personally identify your Windpeak activities. If you are involved in a transaction,
                        we have features that allow parties to the transaction to view your email address and obtain
                        your contact information to help complete the transaction. Further, we may send out notices
                        to you or other community members regarding suspicious activity or policy violations on the
                        app. Additionally, we may share your written correspondence, including emails to the other
                        party to your transaction as part of the dispute resolutions process. To request removal of
                        your personal information from the Windpeak community, contact us at our contact information
                        found below. In some cases, we may not be able to remove your personal information, in which
                        case we will let you know if we are unable to do so and why.</p>


                    <p><strong>Internal Service Providers for Our Operations:</strong> We may use third parties that
                        we refer to as internal service providers to facilitate or outsource one or more aspects of
                        the business (sometimes utilizing framing techniques), product and service operations that
                        we provide to you and therefore we may provide some of your personal information, including
                        your email address, directly to these internal service providers. Any sensitive information
                        that is transmitted to internal service providers involved in any payment processing
                        functions will be encrypted or tokenized. These internal service providers’ are subject to
                        confidentiality agreements with us and other legal restrictions that prohibit their use of
                        the information we provide them for any other purpose except to facilitate the specific
                        outsourced Windpeak related operation, unless you have explicitly agreed or given your prior
                        permission to them for additional uses. If you provide additional information to an internal
                        service provider directly, then their use of your personal information is governed by their
                        applicable privacy policy. Examples of internal service providers include Stripe which is
                        involved in payment processing functions.</p>


                    <p><strong>Legal Requests: </strong>Windpeak cooperates with law enforcement inquiries, as well
                        as other third parties to enforce laws, such as: intellectual property rights, fraud and
                        other rights, to help protect you and the Windpeak community from bad actors. Therefore, in
                        response to a verified request by law enforcement or other government officials relating to
                        a criminal investigation or alleged illegal activity, we can (and you authorize us to)
                        disclose your name, city, state, telephone number, email address, user history, fraud
                        complaints, and quoting and listing history without a subpoena. Without limiting the above,
                        in an effort to respect your privacy and our ability to keep the community free from bad
                        actors, we will not otherwise disclose your personal information to law enforcement or other
                        government officials without a subpoena, court order or substantially similar legal
                        procedure, except when we believe in good faith that the disclosure of information is
                        necessary to: prevent imminent physical harm or financial loss; or report suspected illegal
                        activity.</p>


                    <p>Due to the existing regulatory environment, we cannot ensure that all of your private
                        communications and other personal information will never be disclosed in ways not otherwise
                        described in this Privacy Policy. By way of example (without limiting the foregoing), we may
                        be forced to disclose personal information, including your email address, to the government
                        or third parties under certain circumstances, third parties may unlawfully intercept or
                        access transmissions or private communications, or users may abuse or misuse your personal
                        information that they collect from the app. Therefore, although we use industry standard
                        practices to protect your privacy, we do not promise, and you should not expect, that your
                        personal information or private communications will always remain private. See “Security
                        Section” below.</p>


                    <p>We collect your location-based information for the purpose of locating a place that you may
                        be searching for in your area. We will only share this information with our mapping provider
                        for the sole purpose of providing you this service. You may opt-out of location based
                        services at any time by editing the setting at the device level.</p>


                    <p><strong>Communications from the app</strong></p>


                    <p>We will occasionally send you information on products, services, special deals, and
                        promotions, as well as a newsletter. You are signed up for these emails at registration and
                        you may unsubscribe at any time by going to your account tab and opting out of the emails,
                        or by following the directions to unsubscribe in the email.</p>


                    <p>Out of respect for your privacy, we provide you a way to unsubscribe from each of these
                        communications when we ask for it. If you no longer wish to receive our newsletter and
                        promotional communications, you may opt-out of receiving them by following the instructions
                        included in each newsletter or communication.</p>


                    <p>You will be notified when your personal information is collected by any third party that is
                        not our agent/service provider, so you can make an informed choice as to whether or not to
                        share your information with that party.</p>


                    <p>We will send you strictly service-related announcements on rare occasions when it is
                        necessary to do so. For instance, if our service is temporarily suspended for maintenance,
                        we might send you an email. Generally, you may not opt-out of these communications, which
                        are not promotional in nature. If you do not wish to receive them, you have the option to
                        deactivate your account.</p>


                    <p>Based upon the personal information you provide us, we will send you a welcoming email to
                        verify your email address, username and password. We will also communicate with you in
                        response to your inquiries, to provide the services you request, and to manage your account.
                        Email is the primary means of communication between members doing transactions on the app
                        and you must be able to receive this information to use Windpeak properly.</p>


                    <p>Windpeak may use your mobile phone number to send recurring text (SMS) messages to you in
                        order to provide you notifications about our Services, for operational, promotional, or
                        marketing purposes (with your consent where required by applicable law), and to administer
                        the Windpeak platform<a
                            href="https://help.uship.com/hc/en-us/articles/4416283922839-SMS-Policy-">.</a> Standard
                        message rates may apply for all text messages sent by or to Windpeak. Please contact your
                        mobile phone service provider for details. Windpeak may also message you via push
                        notifications (with your consent where required by applicable law), which you can opt-out of
                        on your mobile device. Standard data rates may apply.</p>


                    <p><strong>Links to Other apps&nbsp;</strong></p>


                    <p>This app contains links to other apps that are not owned or controlled by Windpeak. Please be
                        aware that we are not responsible for the privacy practices of such other apps. We encourage
                        you to be aware when you leave our app and to read the privacy policies of each and every
                        app that collects personal information. This privacy policy applies only to information
                        collected by this Web app.</p>


                    <p>Some portions of the Windpeak platform implement Google Maps mapping services, including
                        Google Maps API(s). Your use of Google Maps is subject to<a
                            href="https://www.google.com/intl/en/policies/terms"> Google’s Terms of
                            Service</a> and<a href="https://www.google.com/policies/privacy"> Google’s Privacy
                            Policy</a>, as may be amended by Google from time to time.</p>


                    <p><strong>Testimonials</strong></p>


                    <p>We display personal testimonials of satisfied customers on our app in addition to other
                        endorsements. We may post your testimonial along with your first name and last initial. If
                        you wish to update or delete your testimonial, you can contact us at help@windpeak.net.</p>


                    <p><strong>Access to Personal Information</strong></p>


                    <p>Upon request we will provide you with information about whether we hold, or process on behalf
                        of a third party, any of your personal information. You may request this information by
                        contacting help@windpeak.net. We will respond to your request within 30 days.</p>


                    <p>If you are a registered user, you may correct or rectify your data at any time. We provide
                        you with tools to access, correct, delete or modify the personal data you provided to us
                        through your member Account page or by contacting us at help@windpeak.net.&nbsp;</p>


                    <p>If you no longer desire to use our service you can deactivate your account at any time by
                        making the change on your member Account page or by contacting help@windpeak.net. You can
                        also manage your privacy settings by accessing profile settings page.</p>


                    <p>If you deactivate your account, or request that your account be deactivated please keep in
                        mind that search engines may still retain copies of your public information in line with
                        their retention policies. You will need to contact those 3rd parties directly to remove your
                        information.</p>


                    <p>We will retain your information for as long as your account is active or as needed to provide
                        you services. We will retain and use your information as necessary to comply with our legal
                        obligations, resolve disputes, and enforce our agreements.&nbsp; You information until any
                        and all compliance obligations are met.</p>


                    <p><strong>Security</strong></p>


                    <p>The security of your personal information is important to us. When you enter sensitive
                        information (such as credit card number) on our registration or order forms, we encrypt that
                        information using secure socket layer technology (SSL).</p>


                    <p>We follow generally accepted industry standards to protect the personal information,
                        including your email address, submitted to us, both during transmission and once we receive
                        it. No method of transmission over the Internet, or method of electronic storage, is 100%
                        secure, however. Therefore, while we strive to use commercially acceptable means to protect
                        your personal information, we cannot guarantee its absolute security.</p>


                    <p><strong>International Privacy Laws&nbsp;</strong></p>


                    <p>If you are visiting the app outside of the United States, please be aware that you are
                        sending information to the United States where our servers are located. That information may
                        then be transferred within the United States or back out of the United States to other
                        countries outside of your country of residence.&nbsp; These countries (including the United
                        States) may not have data protection laws as comprehensive or protective as those in your
                        country of residence; however, our collection, storage, and protection of your Personal
                        Information will be governed by this Privacy Policy.</p>


                    <p><strong>Business Transitions</strong></p>


                    <p>In the event Windpeak goes through a business transition, such as a merger, acquisition by
                        another company, or sale of all or a portion of its assets, your personal information,
                        including your email address, will likely be among the assets transferred. You will be
                        notified via email of any such change in ownership or control of your personal
                        information.</p>


                    <p><strong>Changes in this Privacy Policy</strong></p>


                    <p>If we decide to change our privacy policy, we will post those changes to this privacy policy
                        on our app and other places we deem appropriate so that you are aware of what information we
                        collect, how we use it, and under what circumstances, if any, we disclose it.</p>


                    <p><strong>Contact Us</strong></p>


                    <p><em>If you have any questions or suggestions regarding our privacy policy or to exercise your
                        rights regarding your personal information, please contact us at </em><a
                        href="mailto:help@windpeak.net"><em>help@windpeak.net</em></a><em>.</em></p>
                    <div className="clear"></div>

                </div>
            </Container>
        </div>
    );
}
