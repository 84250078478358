import React, { useEffect } from 'react';

const RedirectToAppStore = () => {
  useEffect(() => {
    const redirectToAppStore = () => {
      const defaultDownloadUrl = 'https://apps.apple.com/us/app/extraspace/id1635079900';
      window.location.href = defaultDownloadUrl;
    };

    // 调用唤醒 App 的逻辑
    redirectToAppStore();
  }, []);

  return (
    <div>
      <p>正在跳转...</p>
    </div>
  );
};

export default RedirectToAppStore;
