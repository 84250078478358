import React from 'react';
import './index.css';
import Container from "react-bootstrap/Container";

export default function TransactionSecurity() {
    return (
        <div className="transaction-wrapper">
            <Container>
                <div className="real-content" itemProp="text">
                    <h3 className="wp-block-heading"><strong>1. What information do we collect and how do we use
                        it?</strong></h3>
                    <p>When you place an order through the Windpeak app you need to be registered and logged into
                        your account. From your account, we will capture your name, email address, phone number and
                        address listed. You will be required to manually enter your credit card details. This
                        information allows us to process and fulfill your order successfully. It also helps us in
                        maintaining your account.</p>
                    <h3 className="wp-block-heading"><strong>2. How does the Windpeak app protect customer
                        information?</strong></h3>
                    <p>When you place orders, we use a secure server. The secure server software (SSL) encrypts all
                        information you input before it is sent to us. Furthermore, all of the sensitive customer
                        data we collect is protected by several layers of encryption and several layers of security
                        to prevent unauthorized access.&nbsp;</p>
                    <h3 className="wp-block-heading"><strong>3. Credit Card Details</strong></h3>
                    <p>The Windpeak app does not store any credit card information on our servers.</p>
                    <h3 className="wp-block-heading">4. Transaction currency</h3>
                    <p>US dollars</p>
                    <div className="clear"></div>
                </div>
            </Container>
        </div>
    );
}
