import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import TwitterIcon from "@/assets/ic_twitter@2x.png";
import FacebookIcon from "@/assets/ic_facebook@2x.png";
import IcIcon from "@/assets/ic_ins@2x.png";
import { FormattedMessage } from "react-intl";

// https://firebase.google.com/docs/web/setup#available-libraries
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebaseConfig";

import "./index.css";

export default function () {
  // // Initialize Firebase
  useEffect(() => {
    logEvent(analytics, "page_view", { page_path: window.location.pathname });
  }, []);

  return (
    <footer className="footer">
      <Navbar expand="lg" className="bg-body-tertiary footer-navbar">
        <Container className={"footer-container"}>
          <Navbar.Brand href="#home" className="footer-logo-title">
            Extra Space
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="footer-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="nav-link-wrapper footer-nav-link">
              <Nav.Link href="/">
                <FormattedMessage id={"nav.link.home"} />
              </Nav.Link>
              <Nav.Link href="/privacy-policy">
                <FormattedMessage id={"nav.link.privacy"} />
              </Nav.Link>
              <Nav.Link href="/transaction-and-security">
                <FormattedMessage id={"nav.link.safety-of-transaction"} />
              </Nav.Link>
              <Nav.Link href="/shipping-policies">
                <FormattedMessage id={"nav.link.transportation-strategy"} />
              </Nav.Link>
              <Nav.Link href="/terms-of-service">
                <FormattedMessage id={"nav.link.terms-of-service"} />
              </Nav.Link>
              <Nav.Link href="/contact-us">
                <FormattedMessage id={"nav.link.contact-us"} />
              </Nav.Link>
              <Nav.Link href="/announcement">Announcement</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="footer-bottom-container">
        <div className="footer-bottom">
          <div>© 2023 ExtraSpace. All rights reserved.</div>
          <div className="footer-bottom-right">
            <p className={"p-follow"}>
              <FormattedMessage id={"follow-us"} />：
            </p>
            <Nav className="footer-bottom-ul">
              <Nav.Link href={"https://facebook.com"} target={"_blank"}>
                <Image className="social-icon" src={FacebookIcon} fluid />
              </Nav.Link>
              <Nav.Link href={"https://facebook.com"} target={"_blank"}>
                <Image className="social-icon" src={IcIcon} fluid />
              </Nav.Link>
              <Nav.Link href={"https://twitter.com"} target={"_blank"}>
                <Image className="social-icon" src={TwitterIcon} fluid />
              </Nav.Link>
            </Nav>
          </div>
        </div>
      </Container>
    </footer>
  );
}
