import React from 'react';
import Container from "react-bootstrap/Container";
import PostForm from './components/form';
import {FormattedMessage} from 'react-intl';


import './index.css'


export default function () {
    return (
        <div className="post-router-wrapper">
            <Container className="post-router-container">
                <center>
                    <h1 className="page-header-title">
                        <FormattedMessage id={"page.post-route.page-header"} defaultMessage={"发布路线"} />
                    </h1>
                </center>
                <PostForm/>
            </Container>
        </div>
    )
}
