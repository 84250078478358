import React, { createContext, useEffect, useState, useCallback } from "react";
import { IntlProvider } from "react-intl";
import jwt from "jsonwebtoken";
import intlMessagesZhCn from "@/i18n/locales/zh-CN";
import intlMessagesEN from "./i18n/locales/en";

import { RouterProvider } from "react-router-dom";
import router from "@/router";

const oneday = 86400000;

let defaultI18nConfig = {
  locale: "zh-CN",
  messages: intlMessagesZhCn,
};

const AppContext = createContext({
  phone: "",
  token: "",
  isLoggedIn: false,
  onLogin: () => {},
  onLogout: () => {},
  currentLanguage: defaultI18nConfig,
  setCurrentLanguage: () => {},
});

const languageList = ["en", "zh-CN"];

const currentLanguageKey = "language";

const getLanguageIntlMessages = (val) => {
  localStorage.setItem(currentLanguageKey, val);
  switch (val) {
    case "en":
      return {
        locale: val,
        messages: intlMessagesEN,
      };
    case "zh-CN":
      return {
        locale: val,
        messages: intlMessagesZhCn,
      };
    default:
      return defaultI18nConfig;
  }
};

let logoutTimer;

const calculateRemainDuration = (expTime) => {
  const currentTime = new Date().getTime(); // timestamp in ms
  const formatedExp = new Date(expTime).getTime();
  const remainDuration = formatedExp - currentTime;

  return remainDuration;
};

const getLocalToken = () => {
  const localPhone = localStorage.getItem("phone");
  const localToken = localStorage.getItem("token");
  const localExp = parseFloat(localStorage.getItem("expTime"));

  const remainDuration = calculateRemainDuration(localExp);
  if (remainDuration <= 0) {
    localStorage.removeItem("phone");
    localStorage.removeItem("token");
    localStorage.removeItem("expTime");
    localStorage.removeItem("uid");
    return null;
  }

  return { token: localToken, duration: remainDuration, phone: localPhone };
};

const ContextProvider = () => {
  const tokenInfo = getLocalToken();
  let initialPhone;
  let initialToken;
  if (tokenInfo) {
    initialToken = tokenInfo.token;
    initialPhone = tokenInfo.phone;
  }
  const [phone, setPhone] = useState(initialPhone);
  const [token, setToken] = useState(initialToken);
  var isLoggedIn = !!token;

  const loginHandler = (token, phone) => {
    const payload = jwt.decode(token);

    localStorage.setItem("phone", phone);
    localStorage.setItem("token", token);
    localStorage.setItem("expTime", payload.expires * 1000);
    localStorage.setItem("uid", payload.token_id);
    setPhone(phone);
    setToken(token);
    const remainDuration = calculateRemainDuration(payload.expires * 1000);

    if (remainDuration <= oneday) {
      logoutTimer = setTimeout(logoutHandler, remainDuration); // 自动登出
    }

    // 跳转
    router.navigate("/orders");
  };

  const logoutHandler = useCallback((gotoLogin) => {
    setToken(null);
    setPhone(null);
    localStorage.removeItem("phone");
    localStorage.removeItem("token");
    localStorage.removeItem("expTime");
    localStorage.removeItem("uid");
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }

    if (gotoLogin) {
      router.navigate("/login");
    } else {
      router.navigate("/");
    }
  }, []);

  const [currentLanguage, setCurrentLanguage] = useState(defaultI18nConfig);

  useEffect(() => {
    if (tokenInfo) {
      if (tokenInfo.duration < oneday) {
        logoutTimer = setTimeout(logoutHandler, tokenInfo.duration);
      }
    }

    const cacheLanguage = localStorage.getItem(currentLanguageKey);
    if (cacheLanguage !== null) {
      setCurrentLanguage(getLanguageIntlMessages(cacheLanguage));
    }
  }, []);

  const contextValue = {
    phone: phone,
    token: token,
    isLoggedIn: isLoggedIn,
    onLogin: loginHandler,
    onLogout: logoutHandler,
    currentLanguage,
    setCurrentLanguage,
  };
  return (
    <AppContext.Provider value={contextValue}>
      <IntlProvider
        key={currentLanguage.locale}
        locale={currentLanguage.locale}
        messages={currentLanguage.messages}
      >
        <div className="App-Main">
          <RouterProvider router={router} />
        </div>
      </IntlProvider>
    </AppContext.Provider>
  );
};
export { ContextProvider, AppContext, getLanguageIntlMessages, languageList };
