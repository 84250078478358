import React from 'react';
import Container from "react-bootstrap/Container";
import './index.css';

export default function TermsOfService() {
    return (
        <div className="transaction-wrapper">
            <Container>
                <div className="real-content" itemProp="text">
                    <ol>
                        <li>Introduction</li>
                    </ol>
                    <p>Extraspace, a mobile application developed by Windpeak Corporation, is an online marketplace
                        that connects shippers with providers of transportation and logistics services. By accessing
                        or using the mobile application (the "Platform"), you agree to be bound by these terms of
                        service and our privacy policy. If you do not agree to these terms, you may not use the
                        Platform.</p>
                    <ol start="2">
                        <li>Definitions.</li>
                    </ol>
                    <p>Shipper. A person or company that arranges to have goods shipped on their own behalf by any
                        type of conveyance ("Shipper"). The Shipper might be the consignee (recipient of the
                        shipment) or the consignor (sender of the shipment), or a third party that is
                        neither.&nbsp;</p>
                    <p>Provider. Any party, person, agent, or Carrier that provides freight (or passenger)
                        transportation and related services to a Shipper or agency. For the transportation of goods,
                        this includes Carriers, Brokers, freight forwarders, and third-party logistics
                        providers.&nbsp;</p>
                    <p>Carrier. A person or company who provides transportation of goods (or passengers) for
                        compensation.&nbsp;</p>
                    <p>Broker. A person who, for compensation, arranges, or offers to arrange, the transportation of
                        goods by an authorized motor carrier. Motor carriers, or persons who are employees or bona
                        fide agents of Carriers, are not Brokers within the meaning of this definition when they
                        arrange or offer to arrange the transportation of shipments that they are authorized to
                        transport and they have accepted and legally bound themselves to transport.</p>
                    <ol start="3">
                        <li>Eligibility</li>
                    </ol>
                    <p>You must be at least 18 years old and able to enter into legally binding contracts to use the
                        Platform. By using the Platform, you represent and warrant that you meet these
                        requirements.</p>
                    <ol start="4">
                        <li>Service Description</li>
                    </ol>
                    <p>Windpeak allows shippers to list their transportation needs on the Platform and Windpeak
                        matches the needs with providers. Providers can then accept the offer to transport the
                        items. After a shipping agreement is made between a shipper and provider, Windpeak
                        facilitates the payment between them. Windpeak also allows providers and shippers to rate
                        and review each other to build a reliable network.</p>
                    <ol start="5">
                        <li>Provider Obligations</li>
                    </ol>
                    <p>You must legally be able to transport the shipments you engage with through Windpeak. You
                        agree that it is your sole obligation to comply with all laws, rules, statutes, and
                        regulations that may apply including any and all local, state, and federal licensing
                        requirements. Providers agree that, through the various products and features on Windpeak,
                        they are solely responsible for all aspects of services and terms they offer the Shipper.
                        Services and terms are solely provided by the providers and not by Windpeak. Windpeak does
                        not verify or adhere to the terms put forth by the provider, which are available on the
                        provider’s public profile. These provider terms may not directly violate Windpeak’s User
                        Agreement. You understand and acknowledge that all shipment information is provided by other
                        registered users and Windpeak has no control over or responsibility for the accuracy of this
                        information. You are responsible for reading all of the shipment details, and by booking
                        shipments on Windpeak, you are entering into a legally binding agreement to complete the
                        services as requested by the Shipper.</p>
                    <ol start="6">
                        <li>Shipper Obligations</li>
                    </ol>
                    <p>Shippers are responsible for providing accurate information about the items to be shipped,
                        including size, weight, and any special handling requirements. Shippers must also comply
                        with all applicable laws and regulations related to the item(s) being shipped, including but
                        not limited to, import/export regulations and compliance with any applicable laws or
                        regulations that might be related to the item(s) being shipped.</p>
                    <ol start="7">
                        <li>Payment and Fees</li>
                    </ol>
                    <p>Shippers are responsible for paying providers for the transportation services they receive.
                        Windpeak will facilitate payment, with provider and shipper both agreeing on the price.
                        Shippers will be charged a service fee by Windpeak for using the platform, which will be
                        disclosed to them before the transaction is completed.</p>
                    <p>If you register to create an account to receive payments via our application, you expressly
                        authorize Windpeak’s service provider, Dwolla, Inc. to originate credit transfers to your
                        financial institution account. You must be at least 13 years old and obtain parental
                        permission if under 18 to receive funds. You authorize us to collect and share with Dwolla
                        your personal information including full name, email address and financial information, and
                        you are responsible for the accuracy and completeness of that data. Dwolla’s Privacy Policy
                        is available <a href="https://www.dwolla.com/legal/privacy/">here.</a>&nbsp;</p>


                    <ol start="8">
                        <li>Termination</li>
                    </ol>


                    <p>Either you or Windpeak may terminate your use of the Platform at any time for any reason.</p>


                    <ol start="9">
                        <li>Dispute Resolution</li>
                    </ol>


                    <p>Any dispute arising out of or relating to these terms of service or the use of the Platform
                        shall be resolved through binding arbitration in accordance with the commercial arbitration
                        rules of the American Arbitration Association.</p>
                    <ol start="10">
                        <li>Miscellaneous</li>
                    </ol>
                    <p>These terms of service constitute the entire agreement between you and Windpeak regarding the
                        use of the Platform, and supersede any prior agreements between you and Windpeak. If any
                        provision of these terms of service is found to be invalid or unenforceable, the remaining
                        provisions shall remain in full force and effect.</p>
                    <div className="clear"></div>
                </div>
            </Container>
        </div>
    );
}
