export default {
    "page.post-route.page-header": "Post Route",
    "page.post-route.pickup-location": "Pickup location",
    "page.post-route.delivery-location": "Delivery location",
    "page.post-route.truck-space-with-remarks": "Truck space with remarks",
    "page.post-route.truck-space-size": "Inches",
    "page.post-route.btn-send-code": "Send code",
    "page.post-route.code": "Code",
    "page.post-route.phone": "Phone",
    "page.post-route.note": "Note",
    "page.post-route.btn-submit": "Post route",
    "page.post-route.phone-placeholder": "please input phone",
    "page.post-route.code-placeholder": "please input code",
    "page.post-route.note-placeholder": "please input note",
    "page.post-route.pickup-placeholder": "please select pickup location",
    "page.post-route.delivery-placeholder": "please select delivery location",
    "page.post-route.length-placeholder": "please input length",
    "page.post-route.width-placeholder": "please input width",
    "page.post-route.height-placeholder": "please input height",
    "page.post-route.success-title": "Route Published Successfully"
}