export default {
    "page.home.slogan-looking-for-a-transport-solution": "Looking for a Transport Solution?",
    "page.home.choose": "Choose",
    "page.home.extra-space": "ExtraSpace",
    "page.home.btn.download-app": "Download App",
    "page.home.btn.post-router": "Post Route",
    "page.home.for-shipper-advantages": "For Shipper, Advantages of ExtraSpace",
    "page.home.for-carrier-advantages": "By joining ExtraSpace, you will enjoy",
    "page.home.registered-user": "Registered User",
    "page.home.registered-carrier": "Registered Carrier",
    "page.home.completed-order": "Completed Order",
    "page.home.advantages-of-extra-space": "Advantages of ExtraSpace",
    "page.home.for-shipper-description-first": "Are you searching for a reliable, efficient, and cost-effective transportation platform? Welcome to ExtraSpace!",
    "page.home.for-shipper-description-second": "ExtraSpace is a platform specifically designed to meet the demands of medium to large-sized goods transportation. We're committed to simplifying the entire transport process, making us your go-to choice for delivery needs.",
    "page.home.trustworthy-choice": "A Trustworthy Choice",
    "page.home.trustworthy-choice-description": "Our platform brings together a plethora of professional transport companies. Each order undergoes strict auditing and tracking to ensure the secure arrival of your goods.",
    "page.home.efficiency-and-simplicity": "Efficiency and Simplicity",
    "page.home.efficiency-and-simplicity-description": "With our app, you can quickly place an order in just a few simple steps. Simply input your cargo information and click to confirm - done!",
    "page.home.fair-pricing": "Fair Pricing",
    "page.home.fair-pricing-description": "At ExtraSpace, you don't have to worry about transporting medium to large-sized goods. We offer competitive prices to alleviate your transport demands.",
    "page.home.catering-to-medium-to-large-sized-goods": "Catering to Medium to Large-sized Goods",
    "page.home.catering-to-medium-to-large-sized-goods-description": "Whether it's furniture, equipment, or any other medium to large-sized goods, ExtraSpace can easily handle it. Our platform is dedicated to helping every cargo find the right transport company.",
    "page.home.carrier-join-extra-space": "Join ExtraSpace and Unlock the Full Potential of Your Trucks!",
    "page.home.for-carrier-description-first": "Are you a transport company looking for more business opportunities? Have you ever been bothered by the wasted space in half-filled trucks? Now, ExtraSpace provides a solution for you!",
    "page.home.for-carrier-description-second": "ExtraSpace is an online platform focusing on the transportation of medium to large-sized goods. We encourage transport companies to fully utilize their truck space. Regardless of whether the space is in a completely empty truck, or there is only a small amount of leftover space, we can help you find the right order and turn that space into extra profits.",
    "page.home.carrier-endless-opportunities": "Endless Opportunities",
    "page.home.carrier-endless-opportunities-description": "Our platform is home to a large number of users who need to transport medium to large-sized goods. Regardless of how much space remains in your vehicle, we can match you with suitable orders, keeping your vehicles always running efficiently.",
    "page.home.carrier-reliable-guarantees": "Reliable Guarantees",
    "page.home.carrier-reliable-guarantees-description": "We ensure that you get timely and fair remuneration for each transport.",
    "page.home.carrier-fast-payment": "Fast Payment",
    "page.home.carrier-fast-payment-description": "Our payment system is fully automated. Once an order is completed, your payment will be automatically sent to your account within 3 days.",
    "page.home.carrier-simplified-operations": "Simplified Operations",
    "page.home.carrier-simplified-operations-description": "With our App, you can easily receive and manage orders, making transportation simpler and more convenient.",
    "page.home.carrier-join-description": "Join ExtraSpace, make full use of your transport resources, unleash the full potential of your trucks, and create more profits for you! We look forward to your participation, let us together build a smarter and more efficient transportation future!",
    "page.home.carrier-join": "Join ExtraSpace",
    "page.home.carrier-join-subtitle": "Unleash the full potential of your trucks, and create more profits for you!",
    "page.home.carrier-register-description": "Join ExtraSpace, make full use of your transport resources, unleash the full potential of your trucks, and create more profits for you! We look forward to your participation, let us together build a smarter and more efficient transportation future!",
    "page.home.btn.carrier-join": "Carrier Register",
    "page.home.place-order-online": "Place Order Online",
    "page.home.real-time-order-tracking": "Real-time Order Tracking",
    "page.home.query-order-history": "Query Order History",
    "page.home.credit-card-payment": "Credit Card Payment",
    "page.home.company-vision": "Company Vision",
    "page.home.company-vision-description": "ExtraSpace is committed to driving the future of the transportation industry towards efficiency and innovation. We aspire to create a world where every inch of cargo space is fully utilized, ensuring sustained growth and maximizing opportunities for both transport providers and shippers.",
}