import React from "react";
import { useState, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { FormattedMessage, useIntl } from "react-intl";
import { message, ConfigProvider, List, Empty } from "antd";
import OrderItem from "./components/orderItem";
import { Navigate } from "react-router-dom";
import JSONBig from "json-bigint";

import theme from "@/AntDesignConfig";

import { AppContext } from "@/Context";

import "./index.css";

import { BASE_API_URL } from "@/network.config";

export default function () {
  const appCtx = useContext(AppContext);

  const [messageApi, messageContextHolder] = message.useMessage();

  const [activeTabIndex, setActiveTabIndex] = useState(2);

  const [listLoading, setListLoading] = useState(false);

  const [listParams, setListParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
      total: 0,
    },
  });

  const [ordersData, setOrdersData] = useState([]);

  const cleanData = () => {
    setListParams({
      ...listParams,
      pagination: {
        ...listParams.pagination,
        total: 0,
        current: 1,
        pageSize: 5,
      },
    });
    setOrdersData([]);
  };

  const networkErrorMsg = useIntl().formatMessage({
    id: "message.network.error",
  });
  const tokenErrorMsg = useIntl().formatMessage({
    id: "message.network.error.token",
  });

  const fetchData = async () => {
    try {
      setListLoading(true);

      const params = {
        tab: activeTabIndex,
        page_num: listParams.pagination.current,
        page_size: listParams.pagination.pageSize,
      };
      const tmpParams = new URLSearchParams();
      Object.keys(params).map((key) => tmpParams.append(key, params[key]));

      const rawResponse = await fetch(
        `${BASE_API_URL}/api/v1/app/web/orders?` + tmpParams.toString(),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${appCtx.token}`,
          },
        }
      );
      if (!rawResponse.ok) {
        if (rawResponse.status === 401) {
          // 需要重新登录
          messageApi.error(tokenErrorMsg);
          appCtx.onLogout(true);

          return;
        }
        throw new Error(rawResponse);
      }
      const text = await rawResponse.text();
      const result = JSONBig({ storeAsString: true }).parse(text);

      if (result.code !== 0) {
        console.log(`request login api failed:${result}`);
        messageApi.error(result.errmsg);
        return;
      }

      setListParams({
        ...listParams,
        pagination: {
          ...listParams.pagination,
          total: result.data.total,
        },
      });

      setOrdersData(result.data.data);
    } catch (error) {
      console.log(`request login api error: ${error}`);
      messageApi.error(networkErrorMsg);
    } finally {
      setListLoading(false);
    }
  };

  useEffect(() => {
    cleanData();
    if (
      listParams.pagination.current === 1 &&
      listParams.pagination.pageSize === 5
    ) {
      fetchData();
    }
  }, [activeTabIndex]);

  useEffect(() => {
    fetchData();
  }, [listParams.pagination.current, listParams.pagination.pageSize]);

  return appCtx.isLoggedIn ? (
    <ConfigProvider theme={theme}>
      {messageContextHolder}
      <div className="carrier-order-list-wrapper">
        <Container className="carrier-order-list-container">
          <div class="tabbar">
            <span
              className={`tab-item ${
                activeTabIndex === 2 ? "tab-item-active" : ""
              }`}
              hoverable={false}
              onClick={() => {
                if (activeTabIndex !== 2) {
                  setActiveTabIndex(2);
                }
              }}
            >
              {" "}
              <FormattedMessage id="page.order.tab.intransit" />{" "}
            </span>
            <div className="divider" />
            <span
              className={`tab-item ${
                activeTabIndex === 3 ? "tab-item-active" : ""
              }`}
              hoverable={false}
              onClick={() => {
                if (activeTabIndex !== 3) {
                  setActiveTabIndex(3);
                }
              }}
            >
              {" "}
              <FormattedMessage id="page.order.tab.done" />{" "}
            </span>
          </div>
          <div class="list-wrapper">
            {!ordersData.length && !listLoading ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    {useIntl().formatMessage({
                      id: "page.order.empty.description",
                      description: "空数据",
                    })}
                  </span>
                }
              />
            ) : (
              <List
                loading={listLoading}
                split={false}
                pagination={{
                  ...listParams.pagination,
                  onChange: (page, pageSize) => {
                    if (page !== listParams.pagination.current) {
                      setListParams({
                        ...listParams,
                        pagination: {
                          ...listParams.pagination,
                          current: page,
                        },
                      });
                    }
                    if (pageSize !== listParams.pagination.pageSize) {
                      cleanData();
                    }
                  },
                }}
                dataSource={ordersData}
                renderItem={(item) => (
                  <List.Item className="list-item-rename">
                    <OrderItem {...item} />
                  </List.Item>
                )}
              />
            )}
          </div>
        </Container>
      </div>
    </ConfigProvider>
  ) : (
    <Navigate to="/login" replace relative="path" />
  );
}
