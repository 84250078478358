import React, {useState, useContext} from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import {Button} from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Toast from 'react-bootstrap/Toast';

import picPhone from '@/assets/pic_phone@2x.png';
import iconQRCode from '@/assets/ic_qrcode@2x.png';
import iconArrow from '@/assets/ic_arrow@2x.png';
import iconCheck from '@/assets/ic_check@2x.png';
import wordLine from '@/assets/word.png';
import iconWish from '@/assets/wish_icon.png';

import QrCodeImg from '@/assets/download-qrcode.png';
import {FormattedMessage} from 'react-intl';
import {AppContext} from "@/Context";

import './index.css';

export default function Home() {
    const {currentLanguage} = useContext(AppContext);
    const [showQRDownloadToast, setShowQRDownloadToast] = useState(false)
    return (
        <div className="body-wrapper">
            <div className="body-wrapper-0">
                <Container>
                    <div className="bg-extra-space slogan-wrapper">
                        <h2 className="slogan-title">
                            <FormattedMessage id={"page.home.slogan-looking-for-a-transport-solution"}/>
                        </h2>
                        <div className="slogan-sub-title">
                            <span><FormattedMessage id={"page.home.choose"}/></span>
                            <span>
                            <Image src={wordLine} className={"word-line"}/>
                        </span>
                        </div>
                        <div className="btn-wrapper">
                            <Button
                                variant="secondary"
                                className="btn-download"
                                onClick={()=> {
                                    window.location.href = "https://apps.apple.com/us/app/extraspace/id1635079959"
                                }}
                            >
                                <FormattedMessage id={"page.home.btn.download-app"} defaultMessage={"下载APP"}/>
                            </Button>
                            <Button variant="secondary" className="btn-post-router" onClick={() => {
                                window.location.href = '/post-router'
                            }
                            }>
                                <FormattedMessage id={"page.home.btn.post-router"} defaultMessage={"发布线路"}/>
                            </Button>
                        </div>

                    </div>
                </Container>
            </div>
            <div className="body-wrapper-1">
                <Container>
                    <center>
                        <h1 className="for-shipper-title">
                            <FormattedMessage id={"page.home.for-shipper-advantages"}/>
                        </h1>
                        <div className="description"><FormattedMessage
                            id={"page.home.for-shipper-description-first"}/>
                            <p><FormattedMessage id={"page.home.for-shipper-description-second"}/></p>
                            <div className='video_player'>
                                <iframe src={
                                    currentLanguage.locale === "en" ? 
                                    "https://www.youtube.com/embed/AJlQlIpyIEY?si=7dBo0lq1Ej1VXUGR" : 
                                    currentLanguage.locale === "zh-CN" ? 
                                    "https://www.youtube.com/embed/36wZlPg9Qr8?si=SPKcqiVbMdKR8NCz" : 
                                    currentLanguage.locale
                                } title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    </center>
                    <Row className="body-wrapper-row">
                        <Col xs={12} md={12} lg={3} className="box-item">
                            <span className="block-title-num">01</span>
                            <h2><FormattedMessage id={"page.home.trustworthy-choice"}/></h2>
                            <div className="box-message">
                                <FormattedMessage id={"page.home.trustworthy-choice-description"}/>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={3} className="box-item">
                            <span className="block-title-num">02</span>
                            <h2><FormattedMessage id={"page.home.efficiency-and-simplicity"}/></h2>
                            <div className="box-message">
                                <FormattedMessage id={"page.home.efficiency-and-simplicity-description"}/>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={3} className="box-item">
                            <span className="block-title-num">03</span>
                            <h2><FormattedMessage id={"page.home.fair-pricing"}/></h2>
                            <div className="box-message">
                                <FormattedMessage id={"page.home.fair-pricing-description"}/>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={3} className="box-item">
                            <span className="block-title-num">04</span>
                            <h2><FormattedMessage id={"page.home.catering-to-medium-to-large-sized-goods"}/></h2>
                            <div className="box-message">
                                <FormattedMessage
                                    id={"page.home.catering-to-medium-to-large-sized-goods-description"}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="body-wrapper-2">
                <Container>
                    <center>
                        <h1>
                            <FormattedMessage id="page.home.for-carrier-advantages"/>
                        </h1>
                        <div className="description">
                            <FormattedMessage
                                id={"page.home.for-carrier-description-first"}/>
                            <p>
                                <FormattedMessage id={"page.home.for-carrier-description-second"}/>
                            </p>
                        </div>
                    </center>
                    <Row className="body-wrapper-row">
                        <Col xs={12} md={12} lg={3} className="box-item">
                            <span className="block-title-num">01</span>
                            <h2>
                                <FormattedMessage id={"page.home.carrier-endless-opportunities"}/>
                            </h2>
                            <div className="box-message">
                                <FormattedMessage id={"page.home.carrier-endless-opportunities-description"}/>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={3} className="box-item">
                            <span className="block-title-num">02</span>
                            <h2>
                                <FormattedMessage id={"page.home.carrier-reliable-guarantees"}/>
                            </h2>
                            <div className="box-message">
                                <FormattedMessage id={"page.home.carrier-reliable-guarantees-description"}/>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={3} className="box-item">
                            <span className="block-title-num">03</span>
                            <h2>
                                <FormattedMessage id={"page.home.carrier-fast-payment"}/>
                            </h2>
                            <div className="box-message">
                                <FormattedMessage id={"page.home.carrier-fast-payment-description"}/>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={3} className="box-item">
                            <span className="block-title-num">04</span>
                            <h2>
                                <FormattedMessage id={"page.home.carrier-simplified-operations"}/>
                            </h2>
                            <div className="box-message">
                                <FormattedMessage id={"page.home.carrier-simplified-operations-description"}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="body-wrapper-3">
                <Container className="position-absolute">
                    <Tab.Container id="switch-tabs-wrapper" defaultActiveKey="first">
                        <Row>
                            <Col xs={12} md={4} lg={4}>
                                <Image className="img-phone" src={picPhone}/>
                            </Col>
                            <Col xs={12} md={8} lg={8}>
                                <Nav variant="pills" className="nva-tabs-custom">
                                    <Nav.Item className="col-6">
                                        <Nav.Link eventKey="first">
                                            <div className="nva-tabs-custom-box">
                                                <h1>
                                                    <FormattedMessage id={"nav.shipper"}/>
                                                </h1>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="col-6">
                                        <Nav.Link eventKey="second">
                                            <div className="nva-tabs-custom-box">
                                                <h1>
                                                    <FormattedMessage id={"nav.carrier"}/>
                                                </h1>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Row>
                                            <Col>
                                                <div className="left-box-top">
                                                    <span className="text_19">
                                                        <Image src={iconArrow} fluid/>
                                                    </span>
                                                    <h1 className="sub-title">ExtraSpace App</h1>
                                                </div>
                                                <div className="left-box-bottom">
                                                    <div className="join-description">
                                                        <FormattedMessage id={"page.home.carrier-join-description"}/>
                                                    </div>
                                                    <ul className="join-ul">
                                                        <li><span className="text_45"><Image src={iconCheck}
                                                                                             fluid/></span>
                                                            <FormattedMessage id="page.home.place-order-online"/>
                                                        </li>
                                                        <li><span className="text_45"><Image src={iconCheck}
                                                                                             fluid/></span><FormattedMessage
                                                            id={"page.home.real-time-order-tracking"}/></li>
                                                        <li><span className="text_45"><Image src={iconCheck}
                                                                                             fluid/></span><FormattedMessage
                                                            id={"page.home.query-order-history"}/></li>
                                                        <li><span className="text_45"><Image src={iconCheck}
                                                                                             fluid/></span><FormattedMessage
                                                            id={"page.home.credit-card-payment"}/></li>
                                                    </ul>
                                                    <div className="install-link-wrapper">
                                                        <ButtonGroup>
                                                            <Button variant="secondary" className={"btn-download"}
                                                                    onClick={() => {
                                                                        setShowQRDownloadToast(true)
                                                                    }}>
                                                                <FormattedMessage id={"btn.download"}/>
                                                            </Button>
                                                            <Button variant="secondary" className="btn-qr"
                                                                    onClick={() => {
                                                                        setShowQRDownloadToast(true)
                                                                    }}>
                                                                <Image src={iconQRCode} className={"qr-img"}/>
                                                            </Button>
                                                        </ButtonGroup>
                                                        <Toast autohide={true} className="download-toast"
                                                               show={showQRDownloadToast}
                                                               onClose={() => {
                                                                   setShowQRDownloadToast(false)
                                                               }}>
                                                            <Toast.Header>
                                                                <Image
                                                                    src={QrCodeImg}
                                                                    className="rounded me-2"
                                                                    alt=""
                                                                    fluid
                                                                />
                                                            </Toast.Header>
                                                        </Toast>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Row>
                                            <Col>
                                                <div className="left-box-top">
                                                    <span className="text_19">
                                                        <Image src={iconArrow} fluid/>
                                                    </span>
                                                    <h1 className="sub-title">ExtraSpace App</h1>
                                                </div>
                                                <div className="left-box-bottom">
                                                    <div className="join-description">
                                                        <FormattedMessage id={"page.home.carrier-join-description"}/>
                                                    </div>
                                                    <ul className="join-ul">
                                                        <li><span className="text_45"><Image src={iconCheck}
                                                                                             fluid/></span>
                                                            <FormattedMessage id="page.home.place-order-online"/>
                                                        </li>
                                                        <li><span className="text_45"><Image src={iconCheck}
                                                                                             fluid/></span><FormattedMessage
                                                            id={"page.home.real-time-order-tracking"}/></li>
                                                        <li><span className="text_45"><Image src={iconCheck}
                                                                                             fluid/></span><FormattedMessage
                                                            id={"page.home.query-order-history"}/></li>
                                                        <li><span className="text_45"><Image src={iconCheck}
                                                                                             fluid/></span><FormattedMessage
                                                            id={"page.home.credit-card-payment"}/></li>
                                                    </ul>
                                                    <div className="install-link-wrapper">
                                                        <ButtonGroup>
                                                            <Button variant="secondary" className={"btn-download"}
                                                                    onClick={() => {
                                                                        setShowQRDownloadToast(true)
                                                                    }}>
                                                                <FormattedMessage id={"btn.download"}/>
                                                            </Button>
                                                            <Button variant="secondary" className="btn-qr"
                                                                    onClick={() => {
                                                                        setShowQRDownloadToast(true)
                                                                    }}>
                                                                <Image src={iconQRCode} className={"qr-img"}/>
                                                            </Button>
                                                        </ButtonGroup>
                                                        <Toast autohide={true} className="download-toast"
                                                               show={showQRDownloadToast}
                                                               onClose={() => {
                                                                   setShowQRDownloadToast(false)
                                                               }}>
                                                            <Toast.Header>
                                                                <Image
                                                                    src={QrCodeImg}
                                                                    className="rounded me-2"
                                                                    alt=""
                                                                    fluid
                                                                />
                                                            </Toast.Header>
                                                        </Toast>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </div>
            <div className="body-wrapper-4">
                <div className="bg-div">
                    <Container>
                        <center>
                            <Image src={iconWish} className="icon-wish"/>
                            <h1>
                                <FormattedMessage id={"page.home.company-vision"}/>
                            </h1>
                            <div className="white-line"></div>
                            <p className="wish-description">
                                <FormattedMessage id={"page.home.company-vision-description"}/>
                            </p>
                        </center>
                    </Container>
                </div>
            </div>
        </div>
    )
}