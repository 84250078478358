const theme = {
  token: {
    colorPrimary: "#EA7E30",
    colorPrimaryBorder: "#FBE1C1",
    itemActiveBg: "rgba(255, 255, 255, 0)",
  },
  cssVar: true,
  hashed: false,
};

export default theme;
