import React from "react";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { message, DatePicker, Spin } from "antd";
import dayjs from "dayjs";
import AWS from "aws-sdk";

import { AppContext } from "@/Context";
import { BASE_API_URL } from "@/network.config";

import "../index.css";

import iconOrigin from "@/assets/icon_origin@2x.png";
import iconDestination from "@/assets/icon_destination@2x.png";
import iconAlert from "@/assets/icon_alert@2x.png";

const dateFormat = (value) => value.format("YYYY/MM/DD");
const startTimeFormat = (value) => value.format("HH:mm");
const endTimeFormat = (value) => value.add(4, "hour").format("HH:mm");

const getOrderStatusLabel = (status) => {
  var label = "";
  switch (status) {
    case 2:
      label = useIntl().formatMessage({
        id: "page.order.item.status.2",
        description: "等待取货",
      });
      break;
    case 3:
      label = useIntl().formatMessage({
        id: "page.order.item.status.3",
        description: "运输中",
      });
      break;
    case 4:
      label = useIntl().formatMessage({
        id: "page.order.item.status.4",
        description: "已完成",
      });
      break;
    case 5:
      label = useIntl().formatMessage({
        id: "page.order.item.status.5",
        description: "已完成",
      });
      break;
    default:
      label = "";
      break;
  }
  return label;
};

const parseDateFormat = "YYYY-MM-DD";
const parseTimeFormat = "h:mm a";

export default function (props) {
  const appCtx = useContext(AppContext);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [spinning, setSpinning] = useState(false);
  const [bolUrl, setBOLUrl] = useState(props.bol_url);
  const [podUrl, setPODUrl] = useState(props.pod_url);
  const [pickupDateValue, setPickupDateValue] = useState(
    !!props.pickup_estimated_date && !!props.pickup_estimated_start_time
      ? dayjs(
          props.pickup_estimated_date + " " + props.pickup_estimated_start_time,
          parseDateFormat + " " + parseTimeFormat
        )
      : null
  );
  const [deliveryDateValue, setDeliveryDateValue] = useState(
    !!props.estimated_date && !!props.estimated_start_time
      ? dayjs(
          props.estimated_date + " " + props.estimated_start_time,
          parseDateFormat + " " + parseTimeFormat
        )
      : null
  );

  const networkErrorMsg = useIntl().formatMessage({
    id: "message.network.error",
  });

  const updateDate = async (type, id, date, start, end) => {
    try {
      const rawResponse = await fetch(
        `${BASE_API_URL}/api/v1/app/web/order/expected/${type}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${appCtx.token}`,
          },
          body: JSON.stringify({
            order_id: id,
            estimated_date: date,
            estimated_start_time: start,
            estimated_end_time: end,
          }),
        }
      );
      const result = await rawResponse.json();

      if (result.code !== 0) {
        console.log(`request login api failed:${result}`);
        messageApi.error(result.errmsg);
        return;
      }
    } catch (error) {
      console.log(`request login api error:${error}`);
      messageApi.error(networkErrorMsg);
    } finally {
    }
  };

  const uploadToS3 = async (type, file) => {
    // S3 Bucket Name
    const S3_BUCKET = "opstoolbucket102220-dev";

    // S3 Region
    const REGION = "us-west-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIA26FLWL24EY4HZJMK",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters
    const order_id = props.order_id;
    const file_path = `public/pdf/${dayjs().format("YYYYMMDDHHmmss")}_${type}_${
      props.order_id
    }.pdf`;
    const params = {
      Bucket: S3_BUCKET,
      Key: file_path,
      Body: file,
      ContentType: "application/pdf",
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (_evt) => {})
      .promise();

    setSpinning(true);
    await upload.then((err, data) => {
      console.log(err);
      console.log(data);

      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/`;
      updatePDF(type, order_id, url + file_path);
    });
  };

  const updatePDF = async (type, order_id, path) => {
    try {
      var requestBody = {};
      if (type === "bol") {
        requestBody["bol_url"] = path;
      } else if (type === "pod") {
        requestBody["pod_url"] = path;
      }
      const rawResponse = await fetch(
        `${BASE_API_URL}/api/v1/app/web/orders/${order_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${appCtx.token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
      const result = await rawResponse.json();

      if (result.code !== 0) {
        console.log(`request login api failed:${result}`);
        messageApi.error(result.errmsg);
        return;
      }

      if (type === "bol") {
        setBOLUrl(path);
      } else if (type === "pod") {
        setPODUrl(path);
      }

      messageApi.success(
        useIntl().formatMessage({
          id: "message.network.success.upload",
          description: "上传成功",
        })
      );
    } catch (error) {
      console.log(`request login api error:${error}`);
      messageApi.error(networkErrorMsg);
    } finally {
      setSpinning(false);
    }
  };

  return (
    <div className="order-item-container">
      {messageContextHolder}
      <span className="order-id-text">
        {useIntl().formatMessage({
          id: "page.order.item.title.order.number",
          description: "订单号",
        })}{" "}
        {props.order_id}
      </span>
      <span className="status-text">{getOrderStatusLabel(props.status)}</span>
      <div className="middle-container">
        <div className="left-group">
          <div className="cell">
            <img className="cell-icon" src={iconOrigin} alt="" />
            <span
              className="cell-title"
              style={{ maxWidth: 240 }}
            >{`${props.pickup_city} - ${props.pickup_state} - ${props.pickup_zipcode}`}</span>
          </div>
          <div className="cell">
            <img className="cell-icon" src={iconDestination} alt="" />
            <span
              className="cell-title"
              style={{ maxWidth: 240 }}
            >{`${props.delivery_city} - ${props.delivery_state} - ${props.delivery_zipcode}`}</span>
          </div>
        </div>
        <div className="divider" />
        <div className="right-group">
          <div className="cell">
            <img className="cell-icon" src={iconAlert} alt="" />
            <span className="cell-title">
              {useIntl().formatMessage({
                id: "page.order.item.title.pickup.date",
                description: "取货时间",
              })}
            </span>
            <span className="cell-content">{`${props.pickup_date} - ${props.pickup_date_end}`}</span>
          </div>
          <div className="cell">
            <img className="cell-icon" src={iconAlert} alt="" />
            <span className="cell-title">
              {useIntl().formatMessage({
                id: "page.order.item.title.freight.category",
                description: "货物类别",
              })}
            </span>
            <span className="cell-content">{`${
              !!props.goods_type ? props.goods_type : "-"
            }`}</span>
          </div>
          <div className="cell">
            <img className="cell-icon" src={iconAlert} alt="" />
            <span className="cell-title">
              {useIntl().formatMessage({
                id: "page.order.item.title.freight.size",
                description: "货物尺寸",
              })}
            </span>
            <span className="cell-content">{`${useIntl().formatMessage({
              id: "page.order.item.description.freight.size.length",
              description: "长",
            })} ${props.length}in, ${useIntl().formatMessage({
              id: "page.order.item.description.freight.size.width",
              description: "宽",
            })} ${props.width}in, ${useIntl().formatMessage({
              id: "page.order.item.description.freight.size.height",
              description: "高",
            })} ${props.height}in`}</span>
          </div>
        </div>
      </div>
      {props.status === 2 ? (
        <>
          <p className="estimated-title">{`${useIntl().formatMessage({
            id: "page.order.item.title.estimated.pickup",
            description: "预计取货",
          })} (${useIntl().formatMessage({
            id: "page.order.item.title.estimated.range",
            description: "四小时区间",
          })})`}</p>
          <div className="estimated-container">
            <div className="left-group">
              <span className="date-title">
                {useIntl().formatMessage({
                  id: "page.order.item.title.estimated.pickup.date",
                  description: "取货日期",
                })}
              </span>
              <DatePicker
                className={`date-picker-trigger${
                  !!props.pickup_estimated_date ? "" : " trigger-not-set"
                }`}
                format={dateFormat}
                showNow={false}
                showTime={{
                  showNowBtn: false,
                  format: "HH:mm",
                  hourStep: 1,
                  minuteStep: 30,
                }}
                placeholder={useIntl().formatMessage({
                  id: "page.order.item.placeholder.date",
                  description: "日期选择占位符",
                })}
                suffixIcon={<></>}
                value={pickupDateValue}
                needConfirm
                onChange={(date, _string) => {
                  setPickupDateValue(date);
                }}
                onOk={(selectedValue) => {
                  const date = selectedValue.format(parseDateFormat);
                  const start = selectedValue.format(parseTimeFormat);
                  const end = selectedValue
                    .add(4, "hour")
                    .format(parseTimeFormat);
                  updateDate("pickup", props.order_id, date, start, end);
                }}
              />
            </div>
            <div className="divider" />
            <div className="right-group">
              <span className="date-title">
                {useIntl().formatMessage({
                  id: "page.order.item.title.estimated.pickup.time",
                  description: "取货时间",
                })}
              </span>
              <DatePicker
                className={`time-picker-trigger${
                  !!props.pickup_estimated_date ? "" : " trigger-not-set"
                }`}
                format={startTimeFormat}
                showNow={false}
                showTime={{
                  format: "HH:mm",
                  defaultValue: dayjs("13:00", "HH:mm"),
                  hourStep: 1,
                  minuteStep: 30,
                }}
                placeholder={useIntl().formatMessage({
                  id: "page.order.item.placeholder.time",
                  description: "时间选择占位符",
                })}
                suffixIcon={<></>}
                value={pickupDateValue}
                needConfirm
                onChange={(date, _string) => {
                  setPickupDateValue(date);
                }}
                onOk={(selectedValue) => {
                  const date = selectedValue.format(parseDateFormat);
                  const start = selectedValue.format(parseTimeFormat);
                  const end = selectedValue
                    .add(4, "hour")
                    .format(parseTimeFormat);
                  updateDate("pickup", props.order_id, date, start, end);
                }}
              />
              <span className="time-divider">-</span>
              <DatePicker
                disabled
                className={"end-time"}
                format={endTimeFormat}
                showNow={false}
                showTime={true}
                timepickerProps={{
                  showNowBtn: false,
                  format: "HH:mm",
                  hourStep: 1,
                  minuteStep: 30,
                }}
                placeholder={"-"}
                suffixIcon={<></>}
                value={pickupDateValue}
              />
            </div>
          </div>
        </>
      ) : null}
      {props.status === 3 ? (
        <>
          <p className="estimated-title">{`${useIntl().formatMessage({
            id: "page.order.item.title.estimated.delivery",
            description: "预计送达",
          })} (${useIntl().formatMessage({
            id: "page.order.item.title.estimated.range",
            description: "四小时区间",
          })})`}</p>
          <div className="estimated-container">
            <div className="left-group">
              <span className="date-title">
                {useIntl().formatMessage({
                  id: "page.order.item.title.estimated.delivery.date",
                  description: "送达日期",
                })}
              </span>
              <DatePicker
                className={`date-picker-trigger${
                  !!props.estimated_date ? "" : " trigger-not-set"
                }`}
                format={dateFormat}
                showNow={false}
                showTime={{
                  showNowBtn: false,
                  format: "HH:mm",
                  hourStep: 1,
                  minuteStep: 30,
                }}
                placeholder={useIntl().formatMessage({
                  id: "page.order.item.placeholder.date",
                  description: "日期选择占位符",
                })}
                suffixIcon={<></>}
                value={deliveryDateValue}
                needConfirm
                onChange={(date, _string) => {
                  setDeliveryDateValue(date);
                }}
                onOk={(selectedValue) => {
                  const date = selectedValue.format(parseDateFormat);
                  const start = selectedValue.format(parseTimeFormat);
                  const end = selectedValue
                    .add(4, "hour")
                    .format(parseTimeFormat);
                  updateDate("delivery", props.order_id, date, start, end);
                }}
              />
            </div>
            <div className="divider" />
            <div className="right-group">
              <span className="date-title">
                {useIntl().formatMessage({
                  id: "page.order.item.title.estimated.delivery.time",
                  description: "送达时间",
                })}
              </span>
              <DatePicker
                className={`time-picker-trigger${
                  !!props.estimated_date ? "" : " trigger-not-set"
                }`}
                format={startTimeFormat}
                showNow={false}
                showTime={{
                  format: "HH:mm",
                  defaultValue: dayjs("13:00", "HH:mm"),
                  hourStep: 1,
                  minuteStep: 30,
                }}
                placeholder={useIntl().formatMessage({
                  id: "page.order.item.placeholder.time",
                  description: "时间选择占位符",
                })}
                suffixIcon={<></>}
                value={deliveryDateValue}
                needConfirm
                onChange={(date, _string) => {
                  setDeliveryDateValue(date);
                }}
                onOk={(selectedValue) => {
                  const date = selectedValue.format(parseDateFormat);
                  const start = selectedValue.format(parseTimeFormat);
                  const end = selectedValue
                    .add(4, "hour")
                    .format(parseTimeFormat);
                  updateDate("delivery", props.order_id, date, start, end);
                }}
              />
              <span className="time-divider">-</span>
              <DatePicker
                disabled
                className={"end-time"}
                format={endTimeFormat}
                showNow={false}
                showTime={true}
                timepickerProps={{
                  showNowBtn: false,
                  format: "HH:mm",
                  hourStep: 1,
                  minuteStep: 30,
                }}
                placeholder={"-"}
                suffixIcon={<></>}
                value={deliveryDateValue}
              />
            </div>
          </div>
        </>
      ) : null}
      <div className="actions-container">
        {!!props.delivery_date ? (
          <span className="delivery-date">{`${useIntl().formatMessage({
            id: "page.order.item.title.completion.time",
            description: "完成时间",
          })} ${props.delivery_date}`}</span>
        ) : null}
        {!!bolUrl ? (
          <a
            className="button outline"
            href={bolUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {useIntl().formatMessage({
              id: "page.order.item.button.view.bol",
              description: "查看BOL",
            })}
          </a>
        ) : null}
        {!bolUrl ? (
          <span
            className="button primary"
            onClick={() => {
              const bol_input = document.getElementById("bol_input");
              bol_input.click();
            }}
          >
            {useIntl().formatMessage({
              id: "page.order.item.button.upload.bol",
              description: "上传BOL",
            })}
          </span>
        ) : null}
        {!!podUrl ? (
          <a
            className="button outline"
            href={podUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {useIntl().formatMessage({
              id: "page.order.item.button.view.pod",
              description: "查看POD",
            })}
          </a>
        ) : null}
        {!podUrl ? (
          <span
            className="button primary"
            onClick={() => {
              const pod_input = document.getElementById("pod_input");
              pod_input.click();
            }}
          >
            {useIntl().formatMessage({
              id: "page.order.item.button.upload.pod",
              description: "上传POD",
            })}
          </span>
        ) : null}
      </div>
      <input
        id="bol_input"
        style={{ display: "none" }}
        type={"file"}
        accept=".pdf"
        onChange={(event) => {
          let file = event.target.files[0];
          uploadToS3("bol", file);
        }}
      ></input>
      <input
        id="pod_input"
        style={{ display: "none" }}
        type={"file"}
        accept=".pdf"
        onChange={(event) => {
          let file = event.target.files[0];
          uploadToS3("pod", file);
        }}
      ></input>
      <Spin spinning={spinning} tip="Uploading" size="large" fullscreen />
    </div>
  );
}
