import React from 'react';
import Container from "react-bootstrap/Container";

export default function ShippingPolicies() {
    return (
        <div className="shipping-policies-wrapper content-wrapper">
            <Container>
                <div className="real-content" itemProp="text">
                    <p>If we didn’t deliver your items within expected time, or if your items were damaged, lost or
                        stolen in transit, you may be able to request a refund or an adjusted invoice for your
                        shipment fee. It is the responsibility of the service provider to provide compensation for
                        the value of the items affected.</p>
                    <p>Refunds back to your original payment method are typically credited within 3-7&nbsp;business
                        days of the date they are issued. However, in some rare circumstances, it can take up to 30
                        days,&nbsp;depending on your financial institution’s processing times.</p>
                    <div className="clear"></div>
                </div>
            </Container>
        </div>
    );
}
